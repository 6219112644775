import { Global } from "@emotion/react";
import { responsiveFontSizes, ThemeProvider } from "@mui/material";
import { type AppProps } from "next/app";
import { Inter } from "next/font/google";
import React, { type FunctionComponent } from "react";

import { globalStyles } from "../styles/globals.style";
import { muiTheme } from "../styles/mui.theme";
import { normalizeStyles } from "../styles/normalize.style";

export const inter = Inter({
  subsets: ["latin"],
  weight: ["200", "300", "400", "500", "600", "700", "800"],
});

const App: FunctionComponent<AppProps> = ({ Component, pageProps }) =>
{
  const theme = responsiveFontSizes(muiTheme, { factor: 1.5 });

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${inter.style.fontFamily};
        }
      `}
      </style>
      <ThemeProvider theme={theme}>
        <Global styles={[normalizeStyles, globalStyles]}/>
        <Component {...pageProps}/>
      </ThemeProvider>
    </>
  );
};

export default App;
