import { colors } from "@/styles/styleVariables";

import { type ThemeOptions } from "@mui/material/styles/createTheme";

export const muiComponents: ThemeOptions["components"] = {
  MuiButton: {
    styleOverrides: {
      root: {
        ":hover, :active, :focus": {
          border: "none",
          boxShadow: "none",
          outline: "none",
        },
        "@media (max-width: 500px)": {
          padding: ".4em .8em",
        },
        border: "none",
        borderRadius: "6px",
        boxShadow: "none",
        color: colors.textLightBg,
        outline: "none",
        padding: ".6em 1em",
        textTransform: "none"
      }
    },
    variants: [
      {
        props: {
          color: "primary",
          variant: "contained",
        },
        style: {
          ":disabled": {
            backgroundColor: colors.gray["100"],
            color: colors.gray["400"],
          },
          ":hover, :active, :focus": {
            backgroundColor: colors.gray["200"],
          },
          boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.05);"
        },
      },
      {
        props: {
          color: "primary",
          variant: "text",
        },
        style: {
          ":hover, :active, :focus": {
            opacity: 0.7
          },
        },
      },
    ]
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        color: colors.gray["400"],
      }
    },
    variants: [
      {
        props: {
          color: "primary",
        },
        style: {
          "&.Mui-checked": {
            color: colors.blue["500"],
          },
        },
      },
    ]
  },
  MuiDialogContentText: {
    styleOverrides: {
      root: {
        color: colors.slate["600"]
      }
    }
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        color: colors.slate["900"],
        fontSize: "2rem !important"
      }
    }
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        color: colors.gray["200"],
      }
    },
    variants: [
      {
        props: {
          color: "primary",
        },
        style: {
          "&.Mui-checked": {
            color: colors.blue["500"],
          },
        },
      },
    ]
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        "& .MuiOutlinedInput-root": {
          fieldset: {
            borderColor: colors.slate["200"]
          },
        },
      },
    },
    variants: [
      {
        props: {
          color: "primary",
          variant: "outlined",
        },
        style: {
          "& .MuiInputLabel-root": {
            color: colors.slate["900"],
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: colors.slate["900"],
          },
          "& .MuiOutlinedInput-input::placeholder": {
            color: colors.slate["500"],
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: colors.gray["500"],
            },
            "&:hover fieldset": {
              borderColor: colors.gray["500"],
            },
          },
        },
      },
      {
        props: {
          color: "secondary",
          variant: "outlined",
        },
        style: {
          "& .MuiInputBase-input-MuiOutlinedInput-input": {
            color: colors.white,
          },
          "& .MuiInputLabel-root": {
            color: colors.white,
          },
          "& .MuiInputLabel-root.Mui-focused": {
            color: colors.white,
          },
          "& .MuiOutlinedInput-input::placeholder": {
            color: colors.white,
          },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": {
              borderColor: colors.white,
            },
            "&:hover fieldset": {
              borderColor: colors.white,
            },
            color: colors.white,
          },
          "&:-webkit-autofill": {
            "-webkit-box-shadow": "0 0 0 100px #000 inset",
            "-webkit-text-fill-color": "#fff"
          },
        }
      }
    ],
  },
};

// {
//   props: {
//     color: "secondary",
//       variant: "outlined",
//   },
//   style: {
//     "& .MuiInputBase-input-MuiOutlinedInput-input": {
//       color: colors.white,
//     },
//     "& .MuiInputLabel-root": {
//       color: colors.slate["400"],
//     },
//     "& .MuiInputLabel-root.Mui-focused": {
//       color: colors.white,
//     },
//     "& .MuiOutlinedInput-input::placeholder": {
//       color: colors.white,
//     },
//     "& .MuiOutlinedInput-root": {
//       "&.Mui-focused fieldset": {
//         borderColor: colors.gray["500"],
//       },
//       "&:hover fieldset": {
//         borderColor: colors.gray["500"],
//       },
//     },
//   }
// }
