export const breakpoints = {
  desktop1366: 1365,
  desktop1600: 1599,
  desktop1920: 1919,
  mobile340: 339,
  mobile400: 399,
  mobile500: 499,
  tablet768: 767,
  tablet1024: 1023,
};

