import { colors } from "@/styles/styleVariables";

import { css } from "@emotion/react";

import { breakpoints } from "../../../utils/breakPoints";

export const headerResponsiveBreakPoint = breakpoints.tablet1024;
export const headerAnimationDuration = .5;
export const headerHeightInRem = 8;

export const wrapper = css`
  position: fixed;
  top: 0;
  left: 0;
  height: ${headerHeightInRem}rem;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 10;
  transition: background-color ease ${headerAnimationDuration}s;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;

export const wrapperScrolled = css`
  box-shadow: 0 1px 12px rgba(0, 0, 0, 0.22);
  background-color: rgba(255,255,255,0.8);
  backdrop-filter: blur(50px);
`;

export const headerContentWrapper = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;
`;

export const logo = (isVisible: boolean) => css`
  height: 3.6rem;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  z-index: ${isVisible ? 2 : 1};
  opacity: ${isVisible ? 1 : 0};
  transition: opacity ease ${headerAnimationDuration}s;
  @media screen and (max-width: ${breakpoints.tablet768}px) {
    height: 3.2rem;
  }
`;

export const navAndLanguageSelect = css`
  display: flex;
  align-items: center;
`;

export const navLinkStyles = css`
  display: flex;
  flex-direction: column;
`;

export const languageSelect = (isScrolled: boolean) => css`
  padding: 4px;
  background-color: ${isScrolled ? "rgba(15,23,42,0.1)" : "rgba(255, 255, 255, 0.3)"};
  color: ${isScrolled ? colors.slate["900"] : colors.white};
  transition: all ease ${headerAnimationDuration}s;
  border-radius: 3px;
  border: none;
  outline: none;
  font-size: 1.4rem;
  @media screen and (max-width: ${headerResponsiveBreakPoint}px) {
    margin-right: 5rem;
  }
`;

export const placeholder = css`
  height: ${headerHeightInRem}rem;
  width: 100%;
`;
