import { headerHeightInRem } from "@/components/common/header/Header.style";

import { css, type SerializedStyles } from "@emotion/react";
 
export const contentWrapperWidthPercent = 88;

export const wrapper = (maxWidth: number): SerializedStyles => css`
  max-width: ${maxWidth}px;
  scroll-margin-top: ${headerHeightInRem}rem;
  width: ${contentWrapperWidthPercent}%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;
