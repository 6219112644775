import { inter } from "@/pages/_app";

import { type ThemeOptions } from "@mui/material/styles/createTheme";

export const muiTypography: ThemeOptions["typography"] = {
  body1: {
    fontSize: "1.8rem",
    fontWeight: 400
  },
  body2: {
    fontSize: "1.6rem",
    fontWeight: 300
  },
  button: {
    fontSize: "1.8rem",
    fontWeight: 600,
  },
  fontFamily: inter.style.fontFamily,
  h1: {
    fontSize: "4.8rem",
    fontWeight: 700
  },
  h2: {
    fontSize: "3.6rem",
    fontWeight: 700
  },
  h3: {
    fontSize: "2.7rem",
    fontWeight: 600
  },
  h4: {
    fontSize: "2.0rem",
    fontWeight: 600
  },
  h5: {
    fontSize: "1.5rem",
    fontWeight: 600
  },
  h6: {
    fontSize: "1.2rem",
    fontWeight: 600
  },
  htmlFontSize: 10
};
