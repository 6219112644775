export const colors = {
  backgroundDark: "#03060B",
  backgroundLight: "#F5F5F5",
  blue: {
    50: "#effeff",
    100: "#dbeafe",
    200: "#bfdbfe",
    300: "#93c5fd",
    400: "#60a5fa",
    500: "#3b82f6",
    600: "#2563eb",
    700: "#1d4ed8",
    800: "#1e40af",
    900: "#1e3a8a",
  },
  gray: {
    50: "#f9fafb",
    100: "#f3f4f6",
    200: "#e5e7eb",
    300: "#d1d5db",
    400: "#9ca3af",
    500: "#6b7280",
    600: "#4b5563",
    700: "#374151",
    800: "#1f2937",
    900: "#111827",
  },
  slate: {
    50: "#f1f5f9",
    100: "#f8fafc",
    200: "#e2e8f0",
    300: "#cbd5e1",
    400: "#94a3b8",
    500: "#64748b",
    600: "#475569",
    700: "#334155",
    800: "#1e293b",
    900: "#0f172a",
  },
  textDarkBg: "#F5F5F5",
  textLightBg: "#03060B",
  white: "#FFFFFF"
};

export const contentAreaWidth = 1400;

export const animationProps = {
  initial: { opacity: 0, y: 30 },
  viewport: { once: true },
  whileInView: { opacity: 1, transition: { delay: .2, duration: .5 }, y: 0 },
};
