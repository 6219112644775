import { muiComponents } from "@/styles/mui/mui.components";
import { muiPalette } from "@/styles/mui/mui.palette";
import { muiTypography } from "@/styles/mui/mui.typography";

import { createTheme } from "@mui/material";

export const muiTheme = createTheme({
  components: muiComponents,
  palette: muiPalette,
  typography: muiTypography
});
