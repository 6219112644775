import { contentWrapperWidthPercent } from "@/components/common/contentWrapper/ContentWrapper.style";

import { css } from "@emotion/react";

import { colors, contentAreaWidth } from "./styleVariables";

export const globalStyles = css`
  * {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    text-align: left;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  p {
    min-height: 2rem;
  }
  
  html {
    scroll-behavior: smooth;
    :root {
      font-size: 62.5%;
    }
  }
  
  body {
    font-size: 1.6rem;
  }
  
  html, body {
    overflow-x: hidden;
    color: ${colors.slate["600"]};
    background-color: ${colors.white};
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    color: ${colors.slate["900"]};
  }

  a {
    text-decoration: none;
  }
  
  a, button {
    cursor: pointer;
  }

  strong, span, i, b {
    color: inherit;
    font-size: inherit;
    font-family: inherit;
  }

  span {
    font-weight: inherit;
  }

  strong, b {
    font-weight: 700;
  }

  .grecaptcha-badge { 
    visibility: hidden; 
  }
  
  @media screen and (max-width: 340px) {
    html:root {
      font-size: 56%;
    }
  }

  @supports (font: -apple-system-body) and (-webkit-appearance: none) {
    img[loading="lazy"] {
      clip-path: inset(0.6px)
    }
  }
`;

export const scrollbarHidden = css`
  -ms-overflow-style: none;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const customScrollbar = css`
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #0f0f19 transparent;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }
  *::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.08);
  }
  *::-webkit-scrollbar-thumb {
    background-color: #0f0f19;
    border-radius: 10px;
  }
`;

export const contentWrapperMargin = `calc((100vw - min(${contentAreaWidth}px, calc(100vw * ${contentWrapperWidthPercent / 100}))) / 2)`;

export const centerHorizontally = css`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;
